import { ChangeEvent, Component, FormEvent, MouseEvent } from 'react';
import { formatPhone } from '../../utils/utils';
import { JsonResponse } from '../../types';

import FormInput from '../../components/FormInput';
import IconArrowRight from '../../components/IconArrowRight';
import IconArrowLeft from '../../components/IconArrowLeft';

interface IsEmailValidResponse {
  address: string;
  isValid: boolean;
}

interface QuestionnaireData {
  name: string;
  email: string;
  phone: string;
}

interface BridalFormQuestion {
  key: keyof QuestionnaireData;
  title: string;
  type: string;
  placeholder: string;
  required: boolean;
}

const QUESTIONS: BridalFormQuestion[] = [
  {
    key: 'name',
    title: 'What is your name?',
    type: 'text',
    placeholder: 'Name',
    required: true,
  },
  {
    key: 'email',
    title: 'What is your email address?',
    type: 'email',
    placeholder: 'Email',
    required: true,
  },
  {
    key: 'phone',
    title: 'What is your phone number?',
    type: 'tel',
    placeholder: 'Phone Number',
    required: true,
  },
];

interface State {
  currentStep: number;
  data: QuestionnaireData;
  sending: boolean;
  sent: boolean;
  error: boolean;
  validating: boolean;
  errorMessage: string;
}

class BridalPartnerForm extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      currentStep: 0,
      data: {
        name: '',
        email: '',
        phone: '',
      },
      sending: false,
      sent: false,
      error: false,
      validating: false,
      errorMessage: '',
    };

    // bind
    this.nextStep = this.nextStep.bind(this);
    this.onChange = this.onChange.bind(this);
    this.previousSetup = this.previousSetup.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }

  nextStep = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { currentStep } = this.state;

    if (currentStep === 1) {
      this.setState({ validating: true });
      try {
        const res = (await fetch(`/api/validate-email`, {
          method: 'POST',
          body: JSON.stringify({
            address: this.state.data.email,
          }),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })) as JsonResponse<IsEmailValidResponse>;

        if (res.status !== 200) {
          throw new Error('There was a problem validating email address.');
        }

        const data = await res.json();

        if (!data.isValid) {
          throw new Error('Provided email address is invalid.');
        }

        this.setState({ validating: false, error: false });
      } catch (e: any) {
        console.error(e);
        return this.setState({
          sending: false,
          error: true,
          validating: false,
          errorMessage: e.message,
        });
      }
    }

    if (currentStep === QUESTIONS.length - 1) {
      this.sendEmail();
    } else {
      this.setState({ currentStep: currentStep + 1 });
    }
  };

  sendEmail = async () => {
    this.setState({ sending: true });
    const { data } = this.state;
    const subject = `New Bridal Partner Signup: ${data.email}`;
    const body = `
      Name: ${data.name}  |
      Email: ${data.email}  |
      Phone: ${data.phone}
    `;

    try {
      const res = (await fetch(`/api/send-bridal-partner-email`, {
        method: 'POST',
        body: JSON.stringify({
          subject,
          body,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })) as JsonResponse<string>;

      if (res.status !== 200) {
        throw new Error('There was a problem processing this request.');
      }

      this.setState({ sending: false, sent: true });
    } catch (e) {
      console.error(e);
      this.setState({ sending: false, error: true });
    }
  };

  previousSetup = (event: MouseEvent) => {
    event.preventDefault();
    const { currentStep } = this.state;
    if (currentStep !== 0) {
      this.setState({ currentStep: currentStep - 1 });
    }
  };

  onChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { currentStep } = this.state;
    const value = currentStep === 2 ? formatPhone(event.target.value) : event.target.value;

    this.setState((state) => {
      const data = { ...state.data, [QUESTIONS[currentStep].key]: value };
      return { data };
    });
  };

  render() {
    const { currentStep, data } = this.state;
    const question = QUESTIONS[currentStep];

    return (
      <>
        {this.state.sent ? (
          <h2 className="text-h2-display">Thanks! We will be in touch shortly.</h2>
        ) : (
          <>
            <div className="my-16">
              <div className="flex space-x-8">
                {QUESTIONS.map((q, i) => (
                  <div key={i} className={`h-4 w-4 rounded-full ${i === currentStep ? 'bg-gold-dark' : 'bg-gray'} `} />
                ))}
              </div>
            </div>

            <form onSubmit={this.nextStep} className="space-y-16">
              <FormInput
                darkMode={true}
                label={question.title}
                value={data[question.key]}
                onChange={this.onChange}
                name={question.key}
                type={question.type}
                placeholder={question.placeholder}
                className="w-full"
                errorMessage={
                  this.state.error
                    ? this.state.errorMessage === ''
                      ? 'We were unable to sign you up. Please contact customer support.'
                      : this.state.errorMessage
                    : undefined
                }
              />

              <div className="flex flex-row-reverse justify-between space-x-16 space-x-reverse">
                <button
                  data-testid="nextOrSubmitButton"
                  className="tracker-button-partner-submit-20200820-130755 btn-info btn"
                  disabled={this.state.validating || (!data[question.key] && question.required)}
                  type="submit"
                >
                  {this.state.sending ? 'Submitting...' : currentStep === QUESTIONS.length - 1 ? 'Submit' : 'Next'}
                  <IconArrowRight />
                </button>

                {currentStep !== 0 && (
                  <button
                    className=" tracker-button-partner-back-20200820-130826 btn-default btn"
                    onClick={this.previousSetup}
                  >
                    <IconArrowLeft />
                    Back
                  </button>
                )}
              </div>
            </form>
          </>
        )}
      </>
    );
  }
}

export default BridalPartnerForm;
