import React from 'react';

interface PolaroidProps {}

type Props = React.FC<React.HTMLAttributes<HTMLDivElement> & PolaroidProps>;

const Polaroid: Props = ({ className = '', children, ...props }) => {
  return (
    <div className={`${className} border-8 border-white shadow-3 xs:border-16`} {...props}>
      {children}
    </div>
  );
};

export default Polaroid;
