import React from 'react';
import BridalPartnerForm from 'partials/forms/BridalPartnerForm';
import Line from 'components/Line';
import NextSteps from 'components/NextSteps';
import Layout2x1ImageRight from 'components/Layout2x1ImageRight';
import Layout1x1 from 'components/Layout1x1';
import Layout2x1ImageLeft from 'components/Layout2x1ImageLeft';
import Polaroid from 'components/Polaroid';
import Carousel from 'components/Carousel';
import Layout3x1 from 'components/Layout3x1';
import StarRating from 'components/StarRating';
import Link from 'next/link';
import SalesCalloutSwatches from 'components/SalesCalloutSwatches';
import SalesCalloutInspiration from 'components/SalesCalloutInspiration';
import { withPageStaticProps } from 'utils/header';

interface State {
  signingUp: boolean;
}

class BridalPartners extends React.Component<{}, State> {
  private signUpFormRef: React.RefObject<HTMLDivElement>;

  constructor(props: {}) {
    super(props);
    this.state = {
      signingUp: false,
    };

    this.signUpFormRef = React.createRef();
  }

  private handleScroll = () => {
    this.setState({ signingUp: true });
    this.signUpFormRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  render() {
    return (
      <>
        <div className="bg-gold-dark py-40">
          <div className="row">
            <img
              src="https://gentux.imgix.net/1652391553_GTPartnerLogoWhite.png?fm=jpg&w=503&auto=format"
              className="col-span-10 col-start-2 sm:col-span-8 sm:col-start-2"
              alt="Generation Tux Partner Program Logo"
            />
          </div>
        </div>

        <div className="bg-gold-lighter py-48 text-center">
          <Layout1x1 hero={true}>
            <Polaroid>
              <img
                src="https://gentux.imgix.net/1652390138_GTBCForTheMomentHero.jpg?&w=980&auto=format"
                alt="Bride in wedding dress and groom in black peak lapel tuxedo"
              />
            </Polaroid>
          </Layout1x1>

          <h2 className="text-h2 mb-40 mt-56">JOIN THE GENERATION TUX PARTNER PROGRAM!</h2>

          <button
            onClick={() => {
              this.handleScroll();
            }}
            className="btn-info tracker-btn-bridal_partners-scroll_to_join-220825-150855 btn mt-32"
          >
            Sign Up
          </button>
        </div>

        <div className="bg-white py-48">
          <div className="container">
            <Layout2x1ImageLeft
              contentClassName="h-full flex flex-col justify-center"
              image={
                <Polaroid>
                  <img
                    src="https://gentux.imgix.net/1652389976_GTPSIpad5x7.jpg?&w=375&auto=format"
                    alt="Tablet featuring Generation Tux look builder beside Generation Tux swatches and wedding planning items"
                  />
                </Polaroid>
              }
            >
              {
                <>
                  <h3 className="text-h2">EXCLUSIVE PERKS, TOP STYLES, AND EXCELLENT SERVICE.</h3>
                  <Line />
                  <p className="!my-0">
                    We understand everything has to be perfect on your clients wedding day. With that in mind, we
                    designed a seamless suit and tuxedo rental process you and your wedding couples will love.
                  </p>
                </>
              }
            </Layout2x1ImageLeft>
          </div>
        </div>

        <div className="bg-gold-lighter pt-56 pb-128">
          <Layout2x1ImageRight
            contentClassName="mt-32"
            image={
              <Carousel>
                <img
                  src="https://gentux.imgix.net/1652390336_GTBCFTMBohoHero5x7.jpg?&auto=format"
                  alt="Bride in wedding dress with groom wearing Allure Dark Blue Suit"
                />
                <img
                  src="https://gentux.imgix.net/1652391159_GTBCForTheMomentSwatch.jpg?&auto=format"
                  alt="Generation Tux Champagne Fabric Swatch next to champagne fabric"
                />
                <img
                  src="https://gentux.imgix.net/1652391027_GTBCFTMGroomsmenGroomPiano5x7.jpg?&auto=format"
                  alt="Groom sitting on piano bench wearing Black Peak Lapel Tuxedo"
                />
                <img
                  src="https://gentux.imgix.net/1652391373_GTPFHGTPHTO.jpg?&auto=format"
                  alt="Potential groom retrieving his Generation Tux delivery from his doorstep"
                />
              </Carousel>
            }
          >
            {
              <>
                <h3 className="text-h2">WHY PARTNER WITH US</h3>
                <Line />
                <ul>
                  <li>
                    We have a system designed specifically for weddings, complete with invite management, easy online
                    fitting process, interactive look builder and delivery to everyone in the wedding party 14 days
                    before the wedding.
                  </li>
                  <li>
                    High quality suits and tuxedos for your clients featuring super 130’s and 140’s merino wool and
                    available in trending colors and fashionable fits.
                  </li>
                  <li>Award winning customer experience team available to handle all your clients questions.</li>
                  <li>Dedicated partner representative.</li>
                  <li>Complimentary swatches and free Home Try-On* for the wedding couple.</li>
                  <li>
                    Take advantage of a generous partner program that allows you to pass on exclusive discounts to your
                    client, and receive a reward on every suit or tuxedo rental in the wedding party
                  </li>
                </ul>
              </>
            }
          </Layout2x1ImageRight>
        </div>

        <div className="bg-white py-100">
          <Layout3x1
            block1={
              <Polaroid>
                <img
                  src="https://gentux.imgix.net/1652390818_GTPFHGTPPlanning.jpg?&w=400&auto=format"
                  alt="Couple on couch wedding planning using their tablet computer"
                />
              </Polaroid>
            }
            block2={
              <div className="bg-gold-lighter pt-64 pb-24">
                <div className="mx-32">
                  <img
                    src="https://gentux.imgix.net/1652390477_RobGorman.jpg?&w=75&auto=format"
                    alt="Photo of Rob Gorman in his Black Peak Lapel Tuxedo"
                  />
                  <h4 className="text-h4 mt-32">Rob G.</h4>
                  <p className="my-8 text-sm uppercase text-black">The Brix on the Fox</p>
                  <StarRating rating={5} />
                  <p className="mt-32">
                    “Our clients/couples are pleased with the great service and the promo provided via the link. We have
                    used Generation Tux for our own personal weddings, and can attest to the great service as well. Nice
                    partnership that benefits all involved.”
                  </p>
                </div>
              </div>
            }
            block3={
              <div className="bg-gold-lighter pt-64 pb-24">
                <div className="mx-32">
                  <img
                    src="https://gentux.imgix.net/1652390477_ChristinaBabb.jpg?&w=75&auto=format"
                    alt="Photo of Christina Babb"
                  />
                  <h4 className="text-h4 mt-32">Christina B.</h4>
                  <p className="my-8 text-sm uppercase text-black">Christina Burton Events</p>
                  <StarRating rating={5} />
                  <p className="mt-32">
                    “My Groom and groomsmen love the fit of the suits and tuxes, and I love how they get to try them on
                    way before the wedding day. That way there are no surprises on the day of.”
                  </p>
                </div>
              </div>
            }
          >
            <div className="text-right">
              <h3 className="text-h2 mb-32">HERE'S WHAT REAL PARTNERS HAD TO SAY…</h3>
              <Link href="/reviews">
                <a className="btn-info tracker-cta-bridal_partners-reviews-220518-145526 btn">READ MORE REVIEWS</a>
              </Link>
            </div>
          </Layout3x1>
        </div>

        <div ref={this.signUpFormRef} className="bg-gold-lighter pb-128 pt-80">
          <div className="container">
            <div className="row">
              <div className="col-span-12 sm:col-span-6 sm:col-start-3">
                <h3 className="text-h2">LET'S GET TO KNOW EACH OTHER.</h3>
                <Line />
                {!this.state.signingUp ? (
                  <>
                    <p className="!my-0">
                      Request more information below and a partner representative will reach out to you within one
                      business day.
                    </p>
                    <button
                      onClick={() => this.setState({ signingUp: true })}
                      className="btn-info tracker-btn-bridal_partners-signup_form-220518-145526 btn mt-32"
                    >
                      Sign Up
                    </button>
                  </>
                ) : (
                  <BridalPartnerForm />
                )}
              </div>
            </div>
            <Layout1x1 hero={true} className="mt-100">
              <Polaroid>
                <img
                  src="https://gentux.imgix.net/1652390913_GTBCForTheMomentGroomsmen5x7.jpg?&aut=format"
                  alt="Photo of four groomsmen with drinks on a couch wearing Black Peak Lapel Tuxedos"
                />
              </Polaroid>
            </Layout1x1>
          </div>
        </div>

        <NextSteps
          ctaPageName="bridal-partners"
          salesCallout1={<SalesCalloutSwatches ctaPageName="bridal-partners" />}
          salesCallout2={<SalesCalloutInspiration ctaPageName="bridal-partners" />}
        />
      </>
    );
  }
}

export const getStaticProps = withPageStaticProps();

export default BridalPartners;
